<template>
  <BaseDialog
    title="瑞乘顯示名稱設定"
    :btn1-loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem prop="transaction">
        <template #label>
          <FormItemTooltipLabel label="消費紀錄顯示名稱" :tooltip-width="200">
            您可以自行設定消費紀錄在客人端顯示的文字，至多5個字。
          </FormItemTooltipLabel>
        </template>
        <BaseElInput v-model="formData.transaction" :maxlength="5" show-word-limit />
      </BaseElFormItem>
      <BaseElFormItem prop="passProduct">
        <template #label>
          <FormItemTooltipLabel label="次卡顯示名稱" :tooltip-width="200">
            您可以自行設定次卡在客人端顯示的文字，至多5個字。
          </FormItemTooltipLabel>
        </template>
        <BaseElInput v-model="formData.passProduct" :maxlength="5" show-word-limit />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted, set } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import notifyMessage from '@/config/notifyMessage'
import { useShop } from '@/use/shop'
import { externalPointSourceConfig } from '@/config/externalPoint'
import { UpdatePosPalCustomDisplayName } from '@/api/customModule'
import { get, find } from 'lodash'

export default defineComponent({
  name: 'EditExternalPointDisplaySettingsModal',
  components: { BaseDialog, FormItemTooltipLabel },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { loading, formRef, initFormData, formData, checkForm } = useBaseForm()

    initFormData({
      transaction: null,
      passProduct: null,
    })

    const formRules = computed(() => {
      return {
        transaction: [noEmptyRules()],
        passProduct: [noEmptyRules()],
      }
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      if (!await checkForm(formRef.value)) {
        loading.value = false
        return
      }
      const payload = {
        shopId: shopId.value,
        customDisplayName: [
          {
            label: 'posPalMemberTransaction',
            displayName: formData.transaction,
          },
          {
            label: 'posPalMemberPassProduct',
            displayName: formData.passProduct,
          },
        ],
      }
      const [, err] = await UpdatePosPalCustomDisplayName(payload)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }
    const syncFormData = (data) => {
      const customDisplayName = get(data, '[0].customDisplayName', {})
      const posPalMemberTransaction = find(customDisplayName, {label: 'posPalMemberTransaction'})
      const posPalMemberPassProduct = find(customDisplayName, {label: 'posPalMemberPassProduct'})
      set(formData, 'transaction', get(posPalMemberTransaction, 'displayName', '消費紀錄'))
      set(formData, 'passProduct', get(posPalMemberPassProduct, 'displayName', '次卡'))
    }
    onMounted(() => {
      syncFormData(props.configData)
    })

    return { formRef, initFormData, formData, formRules, loading, onConfirm, externalPointSourceConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
