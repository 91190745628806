import { noEmptyRules } from '@/validation'

export const ecountApiFieldsConfig = {
  ecountComCode: null,
  ecountUserId: null,
  ecountApiCertKey: null,
  ecountWhCd: null,
}
export const ecountApiFieldsRulesConfig = {
  ecountComCode: [noEmptyRules()],
  ecountUserId: [noEmptyRules()],
  ecountApiCertKey: [noEmptyRules()],
  ecountWhCd: [noEmptyRules()],
}
