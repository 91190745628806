import { admin2 } from './instance'

// POST admin.shopCustomModule.createEcountClient
export const CreateEcountClient = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/ecount`,
    data: {
      enabled,
      config,
    },
  })
}

// PUT admin.shopCustomModule.updateEcountClient
export const UpdateEcountClient = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopCustomModule/ecount`,
    data: {
      enabled,
      config,
    },
  })
}

// POST admin.ecommerceProduct.syncExternalProductNo
export const SyncExternalProductNo = async ({
  shopId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/ecommerceProduct/syncExternalProductNo`,
  })
}

// POST admin.ecommerceProduct.queryExternalProductNo
export const QueryExternalProductNo = async ({
  shopId,
  productNos,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/ecommerceProduct/queryExternalProductNo`,
    data: {
      productNos,
    },
  })
}
