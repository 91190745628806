<template>
  <BaseDialog :title="`編輯 ${title}串接設定`" :btn1Loading="loading" width="610px" @close="$emit('close')" @confirm="onConfirm">
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem prop="enabled" :label="`啟用 ${title}串接設定`">
        <BaseElSwitch
          v-model="formData.enabled"
          inactive-text="關閉"
          active-text="開啟"
        />
      </BaseElFormItem>
      <EcountApiForm :formData.sync="formData" :disableFields="formData.enabled" />
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import EcountApiForm from './EcountApiForm.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onMounted } from 'vue'
import { useShop } from '@/use/shop'
import { get, find } from 'lodash'
import { CreateEcountClient, UpdateEcountClient } from '@/api/ecount'
// config
import { ecountApiFieldsConfig, ecountApiFieldsRulesConfig } from '@/config/ecount'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'EcountModuleSettingsEditModal',
  components: { BaseDialog, EcountApiForm },
  props: {
    configData: { type: Array, default: () => [] },
    title: { type: String, default: '' },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enabled: false,
      ...ecountApiFieldsConfig,
    })
    const formRules = {
      ...ecountApiFieldsRulesConfig,
    }

    const submitData = computed(() => {
      const data = { ...formData }
      return data
    })

    const handleSubmit = async (data) => {
      const config = props.configData
      const ecountConfig = find(config, { type: 'ecount' })
      let apiMethod = UpdateEcountClient
      if (!ecountConfig) apiMethod = CreateEcountClient
      console.log('apiMethod', apiMethod)
      const [res, err] = await apiMethod({
        shopId: shopId.value,
        enabled: data.enabled,
        config: { ...data, enabled: undefined },
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = props.configData
      const ecountConfig = find(config, { type: 'ecount' })
      if (!ecountConfig) return
      formData.enabled = get(ecountConfig, 'enabled')
      for (const key in ecountApiFieldsConfig) {
        formData[key] = get(ecountConfig, `config.${key}`)
      }
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }

    onMounted(() => {
      syncData()
    })
    return { formData, formRef, formRules, onConfirm, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
