<template>
  <div class="pospal-api-form">
    <BaseElFormItem
      v-for="(field, index) in formFields"
      :key="index"
      :label="field.label"
      :prop="field.prop"
    >
      <template v-if="field.toolTip">
        <template slot="label">
          <FormItemTooltipLabel :label="field.label" :tooltipWidth="260">
            <div v-html="field.toolTip" />
          </FormItemTooltipLabel>
        </template>
        <BaseElInput v-model="syncFields[field.prop]" :disabled="disableFields" />
      </template>
      <BaseElInput v-else v-model="syncFields[field.prop]" :disabled="disableFields" />
    </BaseElFormItem>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { defineComponent, ref } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'

export default defineComponent({
  name: 'EcountApiForm',
  components: {
    FormItemTooltipLabel,
  },
  props: {
    formData: { type: Object, default: () => ({}) },
    disableFields: Boolean,
  },
  emits: ['update:formData'],
  setup (props, { emit }) {
    const syncFields = useVModel(props, 'formData', emit)
    const formFields = ref([
      { label: 'COM_CODE', prop: 'ecountComCode', toolTip: '登入 ECOUNT ERP 使用的公司 CODE' },
      { label: 'USER_ID', prop: 'ecountUserId', toolTip: '簽發 API_CERT_KEY 的 ECOUNT ID' },
      { label: 'API_CERT_KEY', prop: 'ecountApiCertKey', toolTip: '認證金鑰，由店家管理者於 ERP 後台簽發' },
      { label: 'WH_CD', prop: 'ecountWhCd', toolTip: '倉庫/工廠編碼，由店家自行於 ERP 後台設定' },
    ])

    return {
      syncFields,
      formFields,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
